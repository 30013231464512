import { ApplicationRef, Injectable } from '@angular/core';
import { InsufficientSubscriptionDialogComponent } from './insufficient-subscription-dialog.component';
import { FeaturePermission } from '../../../../../../server/src/companies/model/feature-permissions.interface';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class InsufficientSubscriptionDialogService {
  constructor(private _dialogService: MatDialog,
              private _applicationRef: ApplicationRef) {
  }

  public showInsufficientSubscriptionDialog(missingFeaturePermission: FeaturePermission) {
    this._dialogService.open(InsufficientSubscriptionDialogComponent, {
      disableClose: true,
      data: {
        missingFeature: missingFeaturePermission,
      },
    });

    this._applicationRef.tick();
  }
}
