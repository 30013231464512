import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { ApplicationStateModel, selectAppStateSnapshot, StoreState } from '../../../app.state';
import { areFeaturesEnabled, FeaturePermission } from '../../../../../../server/src/companies/model/feature-permissions.interface';
import {
  InsufficientSubscriptionDialogService,
} from '../../components/insufficient-subscription-dialog/insufficient-subscription-dialog.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface FeaturePermissionsRouteGuardData {
  requiredFeaturePermissions: FeaturePermission[];
}

@Injectable()
export class FeaturePermissionsRouteGuard {
  constructor(private _store: Store,
              private _router: Router,
              private _authenticationService: AuthenticationService,
              private _insufficientSubscriptionDialogService: InsufficientSubscriptionDialogService,
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._authenticationService.isInitialized$
      .pipe(
        filter(_ => _),
        map(_ => {
          const routeData: FeaturePermissionsRouteGuardData = <FeaturePermissionsRouteGuardData>route.data;

          const requiredFeaturePermissions = routeData.requiredFeaturePermissions;

          if (!requiredFeaturePermissions) {
            return false;
          }

          const applicationState = selectAppStateSnapshot(this._store);

          if (!applicationState.featurePermissions) {
            return false;
          }

          const hasPermission = areFeaturesEnabled(requiredFeaturePermissions, applicationState.featurePermissions);

          if (!hasPermission) {
            this._insufficientSubscriptionDialogService.showInsufficientSubscriptionDialog(requiredFeaturePermissions[0]);
          }

          return hasPermission;
        }),
      );
  }
}
