<div class="text-center dialog" style="max-width: 500px">
  @switch (missingFeature) {
    @case (FeaturePermission.WhiteLabeling) {
      <h2>{{ 'SubscriptionUpgradeDialog.WhiteLabel.Title' | translate }}</h2>
      <div class="mt-3">{{ 'SubscriptionUpgradeDialog.WhiteLabel.Description' | translate }}</div>
    }
    @case (FeaturePermission.AddEmployee) {
      <h2>{{ 'SubscriptionUpgradeDialog.AddEmployee.Title' | translate }}</h2>
      <div class="mt-3">{{ 'SubscriptionUpgradeDialog.AddEmployee.Description' | translate }}</div>
    }
    @default {
      <h2>{{ 'SubscriptionUpgradeDialog.Default.Title' | translate }}</h2>
      <div class="mt-3">{{ 'SubscriptionUpgradeDialog.Default.Description' | translate }}</div>
    }
  }

  <div class="mt-4">
    <button mat-button (click)="close()" class="me-2">{{ 'Common.Cancel' | translate }}</button>

    <button mat-flat-button color="primary" (click)="upgradeSubscriptionClick()">
      {{ 'SubscriptionUpgradeDialog.UpgradeButton' | translate }}
    </button>
  </div>
</div>
