import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeaturePermission } from '../../../../../../server/src/companies/model/feature-permissions.interface';
import { RouteNavigationService } from '../../routing/route-navigation.service';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-insufficient-subscription-dialog',
  templateUrl: './insufficient-subscription-dialog.component.html',
  styleUrls: ['./insufficient-subscription-dialog.component.scss'],
  imports: [
    MatButtonModule,
    TranslateModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class InsufficientSubscriptionDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) private _dialogConfig: { missingFeature: FeaturePermission },
    private _routeNavigationService: RouteNavigationService,
    private _dialogRef: MatDialogRef<InsufficientSubscriptionDialogComponent>,
  ) {

  }

  public missingFeature: FeaturePermission = this._dialogConfig.missingFeature;

  public FeaturePermission = FeaturePermission;

  public ngOnInit() {
    if (!this.missingFeature) {
      throw new Error('[missingFeature] is required');
    }
  }

  public close() {
    this._dialogRef.close();
  }

  public async upgradeSubscriptionClick() {
    await this._routeNavigationService.goToPricingPageAsync();

    this.close();
  }
}
